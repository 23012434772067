let getImageFromS3 = (url) =>{
     // return fetch(url, {
     //     method: "GET",
     //     // headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': '*'}
     // })
     //    .then(response => response.blob())
     //    .then(blob => new Promise((resolve, reject) => {
     //        const reader = new FileReader()
     //        reader.onloadend = () => resolve(reader.result)
     //        reader.onerror = reject
     //        reader.readAsDataURL(blob)
     //    }))
    let _main_url = process.env.VUE_APP_SERVER_S3 ? process.env.VUE_APP_SERVER_S3 : '';
    return _main_url + url;
}

export default {getImageFromS3};