<template>
  <div style="font-weight: 600;">
    <div class="d-flex justify-content-between mb-4">
      <div class="d-flex" style="line-height: 1.5;">
        <div>
          <img id="rel_logo" v-if="company.logo_url" :src="company.logo_url" class="logo-w" style="margin-left: auto;width: 114px;" alt="">
        </div>
      </div>

      <div>
        <h2 style="color: #0C6F58;">{{title}}</h2>
        <p><span>{{ $t('report_main.from_date') }}: </span> <span>{{fromDate}}</span></p>
        <p><span>{{ $t('report_main.to_date') }}: </span> <span>{{toDate}}</span></p>
      </div>
    </div>
    <div class="d-flex mb-4">
      <div style="border-right: 1px solid #727272; padding: 0 15px;width:50%;">
        <p><span  style="color: #0C6F58;">{{ $t('report_main.issued_from') }}: </span> <span> {{company.business_name }}</span></p>
        <p><span  style="color: #0C6F58;">{{ $t('report_main.commercial_register') }}: </span> <span> {{company.business_record }}</span></p>
        <p><span  style="color: #0C6F58;">{{ $t('report_main.tax_number') }}: </span> <span> {{company.tax_no }}</span></p>
        <p><span  style="color: #0C6F58;">{{ $t('report_main.address') }}: </span> <span> {{ company.country_name }} - {{ company.city_name }} - {{ company.address }}</span></p>
      </div>
      <div style="border-right: 1px solid #727272; padding: 0 15px;width:50%;">
        <p><span  style="color: #0C6F58;">{{ $t('report_main.issued_to') }}: </span> <span> {{customer.name }}</span></p>
        <p><span  style="color: #0C6F58;">{{ $t('report_main.commercial_register') }}: </span> <span> {{company.commercial_register }}</span></p>
        <p><span  style="color: #0C6F58;">{{ $t('report_main.tax_number') }}: </span> <span> {{company.tax_register }}</span></p>
        <p><span  style="color: #0C6F58;">{{ $t('report_main.address') }}: </span> <span> {{ customer.country_name }} - {{ customer.city_name }} - {{ customer.address }}</span></p>
      </div>
    </div>
  </div>


</template>

<script>
import imageS3Url from "@/core/config/mix/imageS3Url";

export default {
  name: "ReportHeader",
  props: {customer: {}, company:{}, fromDate: null, toDate: null, title: null},
  data() {
    return {
      rel_logo: null,
      data: [],
    }
  },
  methods: {
    ...imageS3Url
  },

}
</script>

<style scoped>
@import '~quill/dist/quill.core.css';
.cairo-400 {font-family: "Cairo", sans-serif;font-optical-sizing: auto;font-weight: 400;font-style: normal;font-variation-settings: "slnt" 0;}
</style>